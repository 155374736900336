import * as React from "react";

const CanvaWorksPresentation = ({ buttonText, buttonLink }) => {

    return (
        <div
            className="canva-container">
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "0",
                    paddingTop: "56.2500%",
                    paddingBottom: "0",
                    boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
                    marginTop: "1.6em",
                    marginBottom: "0.9em",
                    overflow: "hidden",
                    borderRadius: "8px",
                    willChange: "transform"
                }}
            >
                <iframe
                    loading="lazy"
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: "0",
                        border: "none",
                        padding: "0",
                        margin: "0"
                    }}
                    src="https://www.canva.com/design/DAGSgfuBO_A/ZytV3sWx2ekUBl_eXZrIxQ/view?embed"
                    allowFullScreen="allowfullscreen" allow="fullscreen">
                </iframe>
            </div>
            {/*<a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGSgfuBO_A&#x2F;ZytV3sWx2ekUBl_eXZrIxQ&#x2F;view?utm_content=DAGSgfuBO_A&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener"> Teleport "Works" Website</a> by Hello*/}
        </div>
    )
}

export default CanvaWorksPresentation;
