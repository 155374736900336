import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import CanvaWorksPresentation from "../components/CanvaWorksPresentation";



const WorksPage = () => {

    return (
        <>
            <Helmet>
                <title>Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>
            <br/>
            <br/>
            <br/>
            <CanvaWorksPresentation style={{marginTop: "15em", }} />
        </>
    )
}

export default WorksPage;
